"use strict";

document.addEventListener("DOMContentLoaded", function() {

    // menu toggle
    var menu = document.querySelector('.menu-toggle');
    var menuTarget = menu.getAttribute('aria-controls');
    var nav = document.getElementById(menuTarget);
    var subMenus = document.querySelectorAll('.sub-menu');
    var partnerCards = document.querySelectorAll('.js-partner');

    menu.addEventListener('click', function(event){
        event.preventDefault();

        menu.classList.toggle('toggled');
        nav.classList.toggle('toggled');
    });

    partnerCards.forEach(function (listEl){
        listEl.addEventListener('click', function(){
            partnerCards.forEach(function (subListEl){
                if (subListEl === listEl) {
                    subListEl.classList.add("is-active");
                } else {
                    subListEl.classList.remove("is-active");
                }
            });
        });

        listEl.querySelector(".card__close").addEventListener("click", function(event){
            event.stopPropagation();

            listEl.classList.remove("is-active");
        });
    });

    for (var index = 0; index < subMenus.length; index++) {
        var subMenu = subMenus[index];

        subMenu.parentElement.addEventListener('click', function(event){

            if (!this .classList.contains('menu-about')) {

                // event.preventDefault(); // removed by request, won't work well on mobile :(
                subMenu.classList.toggle('toggled');
            }
        });

        subMenu.addEventListener('click', function(event){

            event.stopPropagation();
        });
    }

    var playButtons = document.querySelectorAll('.js-play');

    for (var index = 0; index < playButtons.length; index++) {
        // var button = playButtons[index];
        // console.log(button.getAttribute('data-target'));

        playButtons[index].addEventListener('click', function(event){
            event.preventDefault();

            var target = document.getElementById(this.getAttribute('data-target'));
            var targetFrame = target.getElementsByTagName('iframe')[0];

            target.classList.add('playing');
            targetFrame.src = targetFrame.src + '&autoplay=true';
        });
    }

    var moreButtons = document.querySelectorAll('.js-more');

    for (var index = 0; index < moreButtons.length; index++) {

        moreButtons[index].addEventListener('change', function(event){
            event.preventDefault();

            var targetEl = findAncestor(this, 'li');

            // targetEl.classList.toggle('expanded');
            // this.innerHTML = 'close';
            targetEl.focus();
        });
    }

    var showList = document.querySelectorAll(".js-show-list");

    showList.forEach(function(list_el){
        list_el.addEventListener("click", function (event) {
            event.preventDefault();

            var parent_el = findAncestor(list_el, ".js-expand-parent");
            parent_el.classList.toggle("is-expanded");
        });
    });

    // var migrationLinks = document.querySelectorAll(".migration-link");

    // migrationLinks.forEach(function(link_el){
    //     link_el.addEventListener("mouseover", function () {
    //         var target_id = link_el.getAttribute("data-target");
    //         var target_el = document.getElementById(target_id);

    //         document.querySelectorAll(".is-hovered").forEach(function (link_sub_el){
    //             link_sub_el.classList.remove("is-hovered");
    //         });

    //         target_el.classList.add("is-hovered");
    //     });
    // });

    var header = document.getElementById('masthead');

    window.addEventListener('scroll', function() {

        if (this.scrollY >= 100) {

           header.classList.add('scrolled');

        } else {

            header.classList.remove('scrolled');
        }

    }, false);

    var scrolly = document.querySelector('.scrolly');
    var scrollyHide = document.querySelector('.js-show');

    scrollyHide && scrollyHide.addEventListener('click', function(e){
        e.preventDefault();
        console.log('click')
        scrolly.classList.add('show');
    });


    var getSrc = function getSrc(elem) {
        return elem.getAttribute('data-src');
    };

    var getPrev = function getPrev(elem) {
        return document.getElementById(elem.getAttribute('data-prev'));
    };
    var getNext = function getNext(elem) {
        return document.getElementById(elem.getAttribute('data-next'));
    };

    var openLightbox = function openLightbox(elem) {

        var init = function init(instance) {

            instance.element().querySelector('img').src = '';
            instance.element().querySelector('img').src = getSrc(elem);

            var prev = instance.element().querySelector('#prev');
            var next = instance.element().querySelector('#next');

            prev.onclick = function (e) {

                elem = getPrev(elem);
                init(instance);
            };

            next.onclick = function (e) {

                elem = getNext(elem);
                init(instance);
            };

            document.onkeydown = function(e) {

                switch (e.keyCode) {
                    case 37:
                        elem = getPrev(elem);
                        init(instance);

                        break;

                    case 39:
                        elem = getNext(elem);
                        init(instance);

                        break;
                }
            };
        };

        basicLightbox.create('<img>', {
            beforePlaceholder: '<button id="prev" class="gallery__nav">&#8592;</button>',
            afterPlaceholder: '<button id="next" class="gallery__nav">&#8594;</button>',
            beforeShow: init
        }).show();
    };

    var galleryItems = document.querySelectorAll('.gallery__item');

    for (var index = 0; index < galleryItems.length; index++) {

        galleryItems[index].addEventListener('click', function(e){
            openLightbox(this);
        });
    }

    var slider = document.querySelector('.slider');

    if (slider) {
        var sliderItems = document.querySelectorAll('.slider-nav__item');

        // attach handler for dot clicks which
        // jumps to that slide
        var handleSliderInit = function(){
            var self = this;

            for (var index = 0; index < sliderItems.length; index++) {
                var item = sliderItems[index];

                 item.addEventListener('click', function(e){
                    self.goTo(this.getAttribute('data-index'));
                 });
            }
        };

        // update the currently active little dot
        // to match the slider's current index
        var handleSliderChange = function(e){

            for (var index = 0; index < sliderItems.length; index++) {
                if (this.currentSlide == index) {
                    sliderItems[index].classList.add('active');

                } else {
                    sliderItems[index].classList.remove('active');

                }
            }
        };

        new Siema({
            selector: '.slider',
            onInit: handleSliderInit,
            onChange: handleSliderChange,
        });
    }


    // iterate through all TEXT nodes of the SVG, file their matching
    // PATH or G node and wrap them both in an anchor element
    var linkRegionMap = function(){

        // we use the coalition TEXTs because they contain the same ID as a matching PATH
        // while there may be many PATHs that aren't actually coalitions.
        var coalitionTexts = document.querySelectorAll('.map--region text');

        for (var index = 0; index < coalitionTexts.length; index++) {

            // thanks to the beautiful output of Sketch, the SVG contains a TEXT
            // node and a PATH node or G node with the same ID! Luckily for
            // us the TEXT after, and a duplicate ID always returns the first match
            var textElement = coalitionTexts[index];
            var pathElement = document.getElementById(textElement.id);

            var anchor = document.createElementNS('http://www.w3.org/2000/svg', 'a');
            anchor.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/coalition/' + pathElement.id.toLowerCase());

            wrapElement(pathElement, anchor);
            wrapElement(coalitionTexts[index], anchor);
        }
    }

    linkRegionMap();


    // detect the newsletter form and listen for submit
    // then send a basic POST request with the data serialized
    var  newsletterForms = document.querySelectorAll('.form--newsletter');

    newsletterForms && Array.prototype.forEach.call(newsletterForms, function(el){

        el.addEventListener('submit', function(e){
            e.preventDefault();

            var  newsletterMessage = this.querySelector('.form__message');

            var queryURI = encodeData({
                'action': this.wp_action.value,
                'nonce': this._wpnonce.value,
                'email': this.email.value,
            });

            var xhr = new XMLHttpRequest();

            xhr.open('POST', this.action);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            xhr.onload = function() {
                var response = JSON.parse(xhr.responseText);
                var message = (response && response.data) ? response.data : 'Sorry, it looks like an error occured on our end!';

                newsletterMessage.classList.add('has-message');
                newsletterMessage.innerHTML = message;
            };

            xhr.send(queryURI);
        });
    });

    var msnry = new Masonry('.js-grid', {
        gutter: 40
    });

});

var scrollLinks = document.querySelectorAll(".active.menu-about .sub-menu a");

Array.prototype.forEach.call(scrollLinks, function(el){
    el.addEventListener("click", function(e){
        e.preventDefault();

        var targetEl = document.getElementById(el.hash.substr(1));

        targetEl.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    });
});


// encodes the given object into a usable URI
// with keys and values to be passed via XMLHttpRequest
var encodeData = function(object) {

    return Object.keys(object).map(
        function(k){
            return encodeURIComponent(k) + '=' + encodeURIComponent(object[k])
        }
    ).join('&');
}

// make a simple and quick HEAD XMLHttp request
// to see if the given URL returns 200 or not
var checkURL = function(url) {
    var http = new XMLHttpRequest();

    http.open('HEAD', url, false);
    http.send();

    return http.status == 200;
}

// wrap the given element in the provided wrapper
// and return that wrapper
var wrapElement = function (toWrap, wrapper) {
    console.log(toWrap);
    wrapper = wrapper || document.createElement('div');
    toWrap.parentNode.appendChild(wrapper);

    return wrapper.appendChild(toWrap);
};

function findAncestor(el, sel) {
    while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
    return el;
}
